
/* Firefox scroll style */
.scroll_div{

    /* scrollbar-width: none; */
    scrollbar-width: thin;
    scrollbar-color: var(--Secondary3) var(--UIWhite);
    overflow-y:scroll;
    border: 1px  solid  var(--Primary3);
    height: 94vh;
    width: 100%;
    margin-bottom: .5rem;
}

.scroll_div.height100Percent{

  height: 100%;
}

/* Works on Chrome, Edge, and Safari */
.scroll_div::-webkit-scrollbar {
    /* display: none; */
    width: .5rem;
  }
  
  .scroll_div::-webkit-scrollbar-track {
    /* display: none; */

    background: var(--UIWhite);
    width: .5rem;
  }
  
  .scroll_div::-webkit-scrollbar-thumb {
    /* display: none; */

    background-color: var(--Secondary3);
    /* border-radius: 20px; */
    /* border: 3px solid var(--Secondary3); */
  }

