
.success_image{

    margin-top: 10vh;

}

@media only screen and (max-width: 330px) {
    .success_image{

        width: 10rem;
    
    }
}
