
.black_icon{

    fill: var(--UIBlack);
    /* fill: var(--UIWhite); */
    
}

.white_icon{
    
    fill: var(--UIWhite);
}

