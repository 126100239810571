
.card{

    border-radius: 10px;
    border: 1px solid black;
    background-color: var(--UIWhite);

    box-sizing: border-box;

}


.content{
    width:60vw;
    height: auto;;
}



@media screen and (max-width: 1264px){
    
    .content{
        width: 90vw;
    }
}

@media screen and (max-width: 769px) {

    .content{
        width: auto;

    }
    
}

.width80vw{

    min-width: 80vw
}

/* At ipad Pro (1024 X 1366) (1250 X H , 1250: problems start) change into horizontal */

.sideCard{

    box-shadow: 2px 2px 2px #888888;
    
    width:18vw;
    min-height:440px;
}

.card.register_card{

    width:auto;
    padding: 1rem;
    height: auto;

    overflow: auto;
}

.card.coverPage{
    width: 100vw;
    height: 100vh;
}

.card.CenterText{

    text-align: center;
    
}

@media screen and (max-width: 991.98px) {
    
    .card.register_card{
    
        /* width:90vw; */
        width:auto;
        padding: 1rem;
        height:97vh;
    }
}
@media screen and (orientation:landscape ) and (max-width: 991.98px) {
    
    .card.register_card{
    
        /* width:97vw; */
        width:auto;
        padding: 1rem;
        height:97vh;
    }
}
/* ipad Mini Landscape */
@media only screen and (width:1024px) {
    .card.register_card{
    
        /* width:60vw; */
        width:auto;
        padding: 1rem;
        height:97vh;
    }
}

/* Ipad Pro Protrait Mode */

@media only screen 
and (min-width: 1024px) 
and (max-height: 1366px) 
and (orientation: portrait) 
and (-webkit-min-device-pixel-ratio: 1.5) {

    .card.register_card{
    
        /* width:75vw; */
        width:auto;
        padding: 1rem;
        height:70vh;
    }
}

