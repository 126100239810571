
.svgHeart {
    animation: heartFill 1s cubic-bezier(0.1,0.4,1,0.2) 0s;
    cursor: pointer;
}

/* a.svg-cursor:before {
    content: "";
    display: block;
    position: absolute;
    background-color: transparent;
    cursor: pointer;
    /* plus width and height of the SVG */
    
   
/* }  */

.pointer{

    cursor: pointer;
}

@keyframes heartFill {
    0%{fill:var(--UIWhite)}
    25% {fill:var(--Red4)}
    50% {fill:var(--Red3)}
    75% {fill:var(--Red2)}
    100% {fill:var(--Red)}
}

.svgHeart{
    fill:var(--Red);
    transition:fill 1s cubic-bezier(0.1,0.4,1,0.2) 0s;
    cursor: pointer;
}

.icon__number{

    font-size: var(--x-small);
    font-style: italic;
    font-weight: 525;



}

/* .svgDrain{
    animation: heartDrain .5s cubic-bezier(0.1,0.4,1,0.2) 0s
}

@keyframes heartDrain {
    0%{fill:var(--Red)}
    25% {fill:var(--Red2)}
    50% {fill:var(--Red3)}
    75% {fill:var(--Red4)}
    100% {fill:var(--UIWhite)}
} */
