
.single_bar{

    border-radius: 5px;
    justify-content: flex-start;
    border: 1px solid var(--Primary);
    width: auto;
}

div.flexRowContainer.single_bar{

    width: auto;
}

.single_bar > .bar{
    
    content: "";
    /* width: 100%; */
    height: 100%;
    background-color: var(--Primary);
    border-radius: 5px;

}


p.bar.label{

    font-size: 1rem;
    margin: 0rem .5rem .5rem .5rem;
    box-sizing: border-box;
}

.card.register_card{

    width:auto;
    padding: 1rem;
    height: auto;
}

.card.coverPage{
    width: 100vw;
    height: 100vh;
}

.card.CenterText{

    text-align: center;
    
}

@media screen and (max-width: 991.98px) {
    
    p.bar.label{
    
       font-size: .75rem;
    }
}
@media screen and (orientation:landscape ) and (max-width: 991.98px) {
    
    p.bar.label{
    
        font-size: .75rem;
    }
}
/* ipad Mini Landscape */
@media only screen and (width:1024px) {
    p.bar.label{
    
        font-size: .9rem;
    }
}

@media only screen and (max-width:320px) {
    p.bar.label{
    
        font-size: .85rem;
    }
}
@media only screen and (max-width:280px) {
    p.bar.label{
    
        font-size: .72rem;
    }
}
@media only screen and (max-width:240px) {
    p.bar.label{
    
        font-size: .6rem;
    }
}




