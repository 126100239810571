.loader_container{

    width: 100%;
    text-align: center;
    margin-top: 1rem;
    padding: 3rem;
    box-sizing: border-box;
}

.marginAuto{

    margin: auto;
}

