
.profile_header{
    margin: auto;
    margin-bottom: .5rem;
    position: relative;
    /* height: auto; */
    
}

.profile_header_background{

    /* background-image: url("../../assets/unsplash_GBEHjsPQbEQ.png"); */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: 100%;
    background-position: center;
    width: 100%;
    height: 16rem;
    /* position: absolute; */
    top: 0;
    left: 0;
}

.profile_header_image{

    border-radius: 100%;
    box-shadow: -1px -1px 10px var(--Tertiary3);
    box-sizing: border-box;
    /* border: 1px solid var(--UIWhite); */

    width: 7rem;
    height: 7rem;

    margin-top: 11rem;
    margin-left: 1rem;
}

.profile_header_container{

    margin: 2rem;
    margin-top: 2.6rem;
    margin-left: 3rem;
    
}

.profile_name, .profile_tag{

    margin-top: 0;
    margin-bottom: .2rem;;
    margin-left: .25rem;

}

.profile_other{

    display: flex;
    flex-wrap: wrap;
    gap: 2.5rem;
    margin: .5rem;
    align-items: baseline;
    

}

.profile_other>p{

    color: var(--UIGrey)

}

.profile_following>p{

    color: var(--UIGrey)

}


.profile_description{
    margin: .5rem;
    margin-top: 1rem;
}

.profile_icon{

    width: .9rem;
    margin-right: .5rem;

}

.profile_tag{

    font-weight: normal;
}

.location_icon{
    
    width: .75rem;
}

.link_icon{
    width: 1rem;
}

.link_icon+a{

    text-decoration: none;
}

.link_icon+a:link{

    color: var(--UIGrey);
}
.link_icon+a:visited{

    color: var(--UIGrey);
}
.link_icon+a:hover{

    color: var(--Tertiary);
}
.link_icon+a:active{

    color: var(--Tertiary1);
}

@media screen and (max-width: 1600px){

    .profile_other{

        display: block;
        gap: 1rem;
        margin-top: 0rem;
    
    }

    .profile_following{

        display: flex;
        margin-top: 1.5rem;
        
    }
    .profile_following>p{

        margin: .25rem;
        
    }
    
}



